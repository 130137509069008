import react, {useState} from "react";
import {createRoot} from "react-dom/client"
import "./style.css"

import {
    About,
    Education,
    Footer,
    Landing,
    Navbar,
    Projects,
    Skills
} from "./components/index"

const App = () => {
    return <>
        <div id="bg">
            <Navbar />
            <Landing />
            <Skills />
            <About />
            <Education />
            <Projects />
            <Footer />
        </div>
    </>
}

const app = document.querySelector("#app");
const root = createRoot(app);
root.render(<App />)