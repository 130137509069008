import react from "react";
import headshot from "./images/headshot.jpg"
import "./Landing.css"

const Landing = () => {
    return <>
        <div id="landing">
            <img id="headshot" src={headshot} alt="Regina Galvez Image" />
            <h1>Regina Galvez<hr/>Project Manager</h1>
        </div>
    </>
}

export default Landing;