import react from "react";
import "./Skills.css"

const Skills = () => {
    return <>
        <h2 className="sectionHeader"> Skills and Technical Abilities</h2>
        <div id="skills">
            <p>Meeting Notes</p>
            <p>Schedule Management</p>
            <p>Community Buildings</p>
            <p>Document Management</p>
            <p>Meeting Facilitation</p>
            <p>Virtual Communication</p>
            <p>Google Suite</p>
            <p>Microsoft Office</p>
            <p>Microsoft Project</p>
            <p>Miro Board</p>
            <p>Slack</p>
            <p>WordPress</p>
            <p>Mavenlink</p>
            <p>Quip</p>
            <p>Zoom</p>
            <p>Salesforce Dashboard and Reports</p>
        </div>
    </>
}

export default Skills;