import react from "react";
import scrum from "./images/logos/scrum.png";
import dvc from "./images/logos/dvc.png";
import salesforce from "./images/logos/salesforce.png";
import nvc from "./images/logos/nvc.png";
import yearUp from "./images/logos/yearUp.png";
import google from "./images/logos/google.png";
import "./Education.css";

const Education = () => {

    return <>
    <h2 className="sectionHeader">Education</h2> 
        <div id="education">
            <div className="individualEducation">
                <img className="logo" src={google} alt="Google Project Management Certificate" />
                <h4 className="educationTitle">Google Project Management Certificate</h4>
                <p className="date">September 2022 - Current</p>
                <p><a href="https://coursera.org/share/ffa5d0c5e50a8f8a7b745d2c776615a5" target="_blank">Foundations of Project Management</a></p>
                <p><a href="https://coursera.org/share/77a2316febe9f841f218017b5698a7ae" target="_blank">Project Initiation - Starting a Successful Project</a></p>
                <p><a href="https://coursera.org/share/45395c161ea3a01fa964d037c67bb0d9" target="_blank">Project Planning - Putting it All Together</a></p>
                <p><a href="https://coursera.org/share/33505d18f92785f2ec52cf9a331e7671" target="_blank">Project Execution - Running the Project</a></p>
            </div>

            <div className="individualEducation">
                <img className="logo" src={scrum} alt="SCRUM" />
                <h4 className="educationTitle">Certified Professional Scrum Master - I</h4>
                <p className="date">February 2021</p>
                <p><a href="https://www.scrum.org/certificates/633837" target="_blank">Certification Link</a>, to verify please use galvezregina16@gmail.com</p>
            </div>

            <div className="individualEducation">
                <img className="logo" src={salesforce} alt="Year Up" />
                <h4 className="educationTitle">Salesforce Ranger</h4>
                <p className="date">February 2021 - April 2021</p>
                <p><a href="https://trailblazer.me/id/rgalvez11" target="_blank">Trailblazer profile</a></p>
            </div>

            <div className="individualEducation">
                <img className="logo" src={yearUp} alt="Year Up" />
                <h4 className="educationTitle">Year Up / Diablo Valley College</h4>
                <p className="date">August 2020 - July 2021</p>
                <p>Year Up Student Ambassador</p>
            </div>

            <div className="individualEducation">
                <img className="logo" src={nvc} alt="Year Up" />
                <h4 className="educationTitle">Napa Valley College</h4>
                <p className="date">June 2016 - December 2018</p>
                <p>3.8 GPA</p>
                <p>Phi Theta Kappa Honors Society 2017 - 2019</p>
                <p>Associates Degree in Pre-Health Sciences</p>
            </div>
        </div>
    </>
}

export default Education;
