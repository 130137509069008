import react from "react";
import "./About.css";

const About = () => {
    return <>
        <h2 className="sectionHeader">About Me</h2>
            <div id="about">
                <div>
                <p>I am Regina Salanga Galvez, and I am a proud 1st generation Filipino American that has experience in the tech industry, and I am looking for more opportunities as a Project Manager.</p>

                <p>
                An untraditional success is how I would describe my story.
                In the past I have attended Napa Valley College, to obtain my Associates degree in Pre-Health Sciences. I accomplished this while also being a member of Phi Theta Kappa Honor Society. Through my college years, and I learned that with grit and determination, I can be a go-getter and with success.
                </p>
                <p>
                Things began to transform for me when I changed my trajectory in my career (from Nursing student to Project Management student). I had the opportunity to hone in on my leadership skills through the Year Up program at Diablo Valley College. Through this program, I changed my life within 1 year. 6 months of learning what it takes to be a Project Manager, and 6 months of an earned internship at Salesforce.com.
                </p>
                <p>
                I am now an experienced Project Manager with 1.5 years under my belt, and I have experience in a sales team and experience in events and marketing. I am proud to have completed 3 projects one of the largest events to happen in San Francisco, and my goals are to become an asset to my future team to complete more.
                </p>
            </div>
        </div>
    </>
}

export default About;