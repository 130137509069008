import react, {useState} from "react";
import "./Navbar.css"

const NavBar = () => {
    const [isActive, setIsActive] = useState("");

    const navToggle = () => {
        setIsActive(isActive === "" ? "isActive" : "")
    }

    return <>
        <div id="navbar">
            <button onClick={navToggle} className={`hamburger ${isActive}`}>
                <div className="bar"></div>
            </button>
            <nav className={`internalLinks ${isActive}`}>
                <a onClick={navToggle} href="#landing">Home</a>
                <a onClick={navToggle} href="#skills">Skills</a>
                <a onClick={navToggle} href="#about">About</a>
                <a onClick={navToggle} href="#education">Education</a>
                <a onClick={navToggle} href="#projects">Projects</a>
                <a onClick={navToggle} href="#footer">Contact</a>
            </nav>
        </div>
    </>
}

export default NavBar;