import react from "react";
import "./Projects.css"
import Experience from "./Experience.json"

const Projects = () => {

    const experienceList = Experience.map(experience => (
        <div key={experience.id}>
            <h2 className="projectRole">{experience.role}</h2>
            <h3 className="projectSpecifics">{experience.type}</h3>
            <h3 className="projectSpecifics">{experience.time}</h3>
            {experience.projects.map(project => (
                <div key={project.name}> 
                    <h3 className="projectName">{project.name}</h3>
                    <h4 className="projectSpecifics duration">Project Duration: {project.duration}</h4>
                    <h4 className="projectSpecifics duration">Team Size: {project.teamSize}</h4>
                    
                    {project.name !== "Dreamforce 2022 Badge Design" 
                        ? "" 
                        : <a className="pdf" href="https://drive.google.com/drive/folders/1ShaWX5wtLhJlPnLbCOWkUXXnv2N0Dm9O?usp=share_link" target="_blank">Work Sample</a>
                    }

                    {project.name !== "Dreamforce 2022 Uniforms" 
                        ? "" 
                        : <a className="pdf" href="https://drive.google.com/drive/folders/1vln3NW0rhGmml_YKf7BK3s_9lamsxdDl" target="_blank">Work Sample</a>
                    }

                    {project.name !== "Dreamforce 2022 Dreamstore" 
                        ? "" 
                        : <a className="pdf" href="https://drive.google.com/drive/folders/16BmZWikhBG0S7zeIaKaGSc_3Upp8fARO" target="_blank">Work Sample</a>
                    }

                    {project.name !== "Traffic Managing" 
                        ? "" 
                        : <a className="pdf" href="https://drive.google.com/drive/folders/132hWiBsc2m36wcdDdkB6E_bPb43iWXrP" target="_blank">Work Sample</a>
                    }

                    {project.objectives.map(objective => (
                        <ul>
                            <li key={objective} className="objective">{objective}</li>
                        </ul>
                    ))}
                    <h3 className="results">{project.results}</h3>

                    {!project.timeline ? "" : project.timeline.map(time => (
                        <ul>
                            <li key={time}>{time}</li>
                        </ul>   
                    ))}

                </div>
            ))}
        </div>
    ))

    return <>
        <h2 className="sectionHeader"> Projects </h2>
        <div id="projects">
            <div className="individualProject">{experienceList[0]}</div>
            <div className="individualProject">{experienceList[1]}</div>
        </div>
    </>
}

export default Projects;