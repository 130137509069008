import react from "react";
import "./Footer.css";
import phone from "./images/logos/phone.png";
import email from "./images/logos/email.png";
import linkedIn from "./images/logos/linkedIn.png";
import resume from "./images/logos/resume.png";
import calendly from "./images/logos/calendly.png";

const Footer = () => {
    return <>
        <div id="footer">
            <div id="contact">
            <a href="tel:+17073191875">
                <img className="contactLogo" alt="Phone Number: 1707319185" src={phone}/>
            </a>
            <a href="mailto:galvezregina16@gmail.com">
                <img className="contactLogo" alt="Email address: galvezregina16@gmail.com" src={email}/>
            </a>
            <a href="https://docs.google.com/document/d/19bdIgXnAsTI9DvGEBVNYd1e5i9odzbtY/edit?usp=sharing&ouid=101865683065866231922&rtpof=true&sd=true" target="_blank">
                <img className="contactLogo" alt="Regina Galvez's resume" src={resume}/>
            </a>
            <a href="https://www.linkedin.com/in/regina-galvez-05451b1b2/" target="_blank"> 
                <img className="contactLogo" alt="Regina Galvez's LinkedIn Page" src={linkedIn}/>
            </a>
            <a href="https://calendly.com/galvezregina16/30min?month=2022-10" target="_blank"> 
                <img className="contactLogo" id="calendly" alt="Schedule a meeting with Regina Galvez on Calendly" src={calendly}/>
            </a>
            </div>
            <a href="https://www.linkedin.com/in/felix-cadiz/" target="_blank">
                <p id="copyright">Ⓒ Felix Cadiz</p>
            </a>
        </div>
    </>
}

export default Footer;